<template>
  <main class="vcc-main">
	<h1>🏖Личная страничка администратора 🥰 ❤️</h1>
	<nav>
		<ul class="link-list">
			<li>
				<button-card
					:to="{ name: 'Reports' }"
					text="Отчеты 📊"
				>
					<template v-slot:icon>
						<icon-charts />
					</template>
				</button-card>
			</li>
			<li>
				<button-card
					:to="{ name: 'PastWebinars' }"
					text="Сохранение прошедших вебинаров 🎈"
				>
					<template v-slot:icon>
						<icon-video-page />
					</template>
				</button-card>
			</li>
			<li>
				<button-card
					:to="{ name: 'ArchiveWebinars' }"
					text="Редактирование архива вебинаров 💾"
				>
					<template v-slot:icon>
						<icon-video-page />
					</template>
				</button-card>
			</li>
			<li>
				<button-card
					:to="{ name: 'AdminMaterials' }"
					text="Управление материалами базы знаний"
				>
					<template v-slot:icon>
						<icon-planning/>
					</template>
				</button-card>
			</li>
		</ul>
	</nav>
  </main>
</template>

<script>
import IconCharts from '../../assets/svg/IconCharts.vue'
import IconPlanning from '../../assets/svg/IconPlanning.vue'
import IconVideoPage from '../../assets/svg/IconVideoPage.vue'
import ButtonCard from '../../components/common/ButtonCard.vue'
export default {
	name: 'AdminPage',
	components: {
		ButtonCard,
		IconCharts,
IconVideoPage,
IconPlanning,
	}
}
</script>


<style>
	.link-list {
		list-style: none;
		padding: 0; margin: 30px 0 0 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: max-content;
		gap: 30px;
	}
</style>
